import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/Layout'
import { Container, Flex, Heading, Pager } from 'rendition'
import { BlogPost } from '../components/BlogRoll'
import _ from 'lodash'

const AuthorRoute = (props) => {
  const posts = props.data.allMarkdownRemark.edges
  const author = props.pageContext.author
  const currentPage = props.pageContext.currentPage
  const title = props.data.site.siteMetadata.title
  const totalCount = props.data.allMarkdownRemark.totalCount
  const authorHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } authored by “${author}”`

  return (
    <Layout>
      <Container>
        <Helmet title={`${author} | ${title}`} />
        <Heading.h3>{authorHeader}</Heading.h3>
        <Flex style={{ flexFlow: 'row wrap' }}>
          {posts.map(({ node: post }) => (
            <BlogPost
              image={post.frontmatter.feature_image}
              type={post.frontmatter.postType}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              description={post.excerpt}
              timeToRead={post.timeToRead}
              link={post.fields.slug}
            />
          ))}
        </Flex>
        <Pager
          page={currentPage - 1}
          nextPage={() => {
            navigate(`/authors/${_.kebabCase(author)}/${currentPage + 1}`)
          }}
          prevPage={() => {
            navigate(`/authors/${currentPage - 1}`)
          }}
          itemsPerPage={16}
          totalItems={totalCount}
        ></Pager>
      </Container>
    </Layout>
  )
}

export default AuthorRoute

export const authorPageQuery = graphql`
  query AuthorPage($author: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { authors: { in: [$author] } } }
    ) {
      totalCount

      edges {
        node {
          excerpt(pruneLength: 400)
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featured
            feature_image
            postType
          }
        }
      }
    }
  }
`
